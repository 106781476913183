import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { datadogLogs } from '@datadog/browser-logs';
import { TranslateService } from '@ngx-translate/core';
import {
  AccountStore,
  CreationSource,
  EntitiesStore,
  Incident,
  IncidentStatus,
  IncidentStore,
  IncidentType,
  IncidentView,
  LocalStorageService,
  Maybe,
  RoadEventsStore,
  UsersStore,
} from '@wc/core';
import { RoadEventsPanelSubTabIndexDesktop, RoadEventsPanelTabIndex, RoadEventType } from '@wc/core/models/enums';
import { UIIncident } from '@wc/core/models/extended.models';
import { UiStore } from '@wc/core/stores/ui.store';
import { AlertsService } from '@wc/features/ui/services/alerts.service';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { UnitsQuery } from '@wc/wc-core/src/lib/stores/entities/units/units.query';
import { chipStyles } from '@wc/wc-models/src/lib/enums/general';
import { toJS } from 'mobx';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { AppFeatureEnum, IncidentIndicationType, IncidentSeverity, SplitIOService } from '../../../../core';
import { EntitiesServiceV2, HeapAnalyticsService, ResponsePlanService } from '../../../../wc-core/src';

@Component({
  selector: 'wc-incident-list-item',
  templateUrl: './incident-list-item.component.html',
  styleUrls: ['./incident-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentListItemComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() incident!: UIIncident & IncidentView;
  showUnitAmount = 2;
  isTabletMode!: boolean;
  RoadEventTypeEnum = RoadEventType;
  currentUserId;
  indicationSub: Subscription = new Subscription();
  incidentIndicationTxt: string = '';
  incidentIdTypeofError = 0;
  appFeatureEnum = AppFeatureEnum;
  readonly moment = moment;
  readonly MITIGATION_INDICATION = 'IncidentIndication.MITIGATION';
  readonly STALE_INDICATION = 'IncidentIndication.STALE';
  readonly RESTORED_INDICATION = 'IncidentIndication.RESTORED';
  readonly RESURRECTED_INCIDENT = 'IncidentIndication.RESURRECTED';
  readonly UNCONFIRMED_OVER_TIME = 'IncidentIndication.UNCONFIRMED';
  private readonly tabletIncidentViewPath = 'incidents/';
  private readonly desktopIncidentViewPath = 'live-map/incident/';
  IncidentSeverity = IncidentSeverity;

  get incidentStatus() {
    return IncidentStatus;
  }

  get ChipStyles(): typeof chipStyles {
    return chipStyles;
  }

  handleIncidentStatusIndication() {
    // Filter expired indications based on expired mitigations
    const incidentExpiryObjs = this.incidentStore.indicationExpiryList.filter(item => item.id === this.incident.id);
    const incidentIndications = this.incident.indications.filter(indicationType =>
      incidentExpiryObjs.every(incidentExpiryObj => incidentExpiryObj.indicationType !== indicationType)
    );

    // Determine which indication to show if there are still valid indications
    if (incidentIndications) {
      const currentIndication = this.getIndicationText(incidentIndications);
      if (!this.uiStore.isTabletMode) {
        this.entitiesService.emitNewUIDiff({
          INCIDENT: [{ currentIndication, id: this.incident.id }],
        });
      } else this.incident.currentIndication = currentIndication;

      this.parseIndicationTxt(currentIndication);
    }
  }

  parseIndicationTxt(indicationToShow: IncidentIndicationType) {
    switch (indicationToShow) {
      case IncidentIndicationType.FullyMitigated:
        this.incidentIndicationTxt = this.MITIGATION_INDICATION;
        break;
      case IncidentIndicationType.StaleIncident:
        this.incidentIndicationTxt = this.STALE_INDICATION;
        break;
      case IncidentIndicationType.ReopenedLanes:
        this.incidentIndicationTxt = this.RESTORED_INDICATION;
        break;
      case IncidentIndicationType.ResurrectedIncident:
        this.incidentIndicationTxt = this.RESURRECTED_INCIDENT;
        break;
      case IncidentIndicationType.UnconfirmedOverTime:
        this.incidentIndicationTxt = this.UNCONFIRMED_OVER_TIME;
        break;
    }
    this.cdr.detectChanges();
  }

  // Handles which indication to show
  getIndicationText(indications: IncidentIndicationType[]): IncidentIndicationType {
    let res: IncidentIndicationType;
    if (indications?.length > 1) {
      // In combination with other indications, stale indication will not be shown
      const filteredIndications = indications.filter(indication => indication !== IncidentIndicationType.StaleIncident);
      res = filteredIndications[0];
    } else {
      // single indication
      res = indications[0];
    }
    return res;
  }

  get incidentTypes() {
    return IncidentType;
  }
  get creationSource() {
    return CreationSource;
  }

  constructor(
    public incidentStore: IncidentStore,
    private roadEventsStore: RoadEventsStore,
    public uiStore: UiStore,
    public usersStore: UsersStore,
    public translateService: TranslateService,
    private entitiesStore: EntitiesStore,
    public accountStore: AccountStore,
    private localStorageService: LocalStorageService,
    private alertService: AlertsService,
    private cdr: ChangeDetectorRef,
    private entitiesService: EntitiesServiceV2,
    private router: Router,
    private unitQuery: UnitsQuery,
    private heapService: HeapAnalyticsService,
    public splitIOService: SplitIOService,
    private responsePlanService: ResponsePlanService
  ) {
    this.currentUserId = this.usersStore.authUser.id;
  }

  get affectedLanesShorts() {
    const affectedLanesArray: string[] = [];

    let closedAffectedLanes = this.incident?.affectedLanes?.filter(affectedLane => {
      return affectedLane.isClosed;
    });

    if (closedAffectedLanes?.length && closedAffectedLanes[0]?.positionIndex !== 0) {
      closedAffectedLanes.reverse();
    }

    closedAffectedLanes?.forEach((affectedLane, index) => {
      let affectedLaneItem: string = this.translateService.instant(
        'incidentTitleAffectedLanesShort.' + affectedLane.type
      );
      if (
        affectedLane.type === 'RIGHT_LANE' ||
        affectedLane.type === 'LEFT_LANE' ||
        affectedLane.type === 'CENTRAL_LANE'
      ) {
        affectedLaneItem += affectedLane.number;
      }
      if (index !== closedAffectedLanes?.length - 1) {
        affectedLaneItem += ',';
      }
      affectedLanesArray.push(affectedLaneItem);
    });

    return affectedLanesArray;
  }

  ngOnInit(): void {
    this.isTabletMode = this.uiStore.isTabletMode;
  }

  ngAfterViewInit(): void {
    if (this.incident) {
      if (this.incident.indications?.length > 0) {
        if (this.incident.status === this.incidentStatus.Confirmed) {
          this.handleIncidentStatusIndication();
          this.indicationSub = this.incidentStore.indicationExpiryListChangeId$.subscribe(id => {
            if (this.incident.id === id) {
              this.handleIncidentStatusIndication();
            }
          });
        } else if (this.incident.status === this.incidentStatus.Unconfirmed) {
          if (this.incident.indications.includes(IncidentIndicationType.UnconfirmedOverTime))
            this.parseIndicationTxt(IncidentIndicationType.UnconfirmedOverTime);
          if (this.incident.indications.includes(IncidentIndicationType.ResurrectedIncident))
            this.parseIndicationTxt(IncidentIndicationType.ResurrectedIncident);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.indicationSub.unsubscribe();
  }

  async changeIncidentStatus(event: Event, incident: Incident, status: IncidentStatus) {
    event.stopPropagation();
    if (this.isEditBlocked) return;

    /** This is so we wont play sound for an incident the user itself confirmed */
    if (!this.uiStore.isTabletMode) {
      this.entitiesService.emitNewEntitiesDiff({
        modified: {
          INCIDENT: [
            {
              id: incident.id,
              confirmedAt: new Date().toString(),
              confirmedBy: this.usersStore.authUserID,
            },
          ],
        },
        removed: {},
      });
    } else {
      incident['confirmedBy'] = this.usersStore.authUserID;
      incident['confirmedAt'] = new Date().toString();
    }

    if (status === IncidentStatus.Rejected) {
      this.incidentStore.startCompleteReject(this.incident, status, false);
    } else if (status === IncidentStatus.Completed) {
      this.responsePlanService.responsePlanNotDoneDialogResult(this.incident, status, false);
    } else if (status === IncidentStatus.Confirmed) {
      this.incidentStore.setIncidentStatus(incident, status).subscribe(() => {
        this.alertService.success(this.translateService.instant('notifications.incidentConfirmed'));

        this.uiStore.setRoadEventsPanelTabIndex(RoadEventsPanelTabIndex.InProgress);
        this.uiStore.setRoadEventsInProgressSubTabIndex(RoadEventsPanelSubTabIndexDesktop.Incidents);
      });
    }

    if (incident.creationSource !== 'USER') {
      const timeDiff = moment.duration(moment(Date.now()).diff(incident.startedAt));
      const timeDiffInSeconds = timeDiff.asSeconds();

      if (timeDiffInSeconds <= 900) {
        this.roadEventsStore.interactedRoadEventIds['incident']?.push(incident.id);
        this.localStorageService.set(
          LocalStorageKeys.InteractedRoadEventIds,
          this.roadEventsStore.interactedRoadEventIds
        );
      }
    }
  }

  get associatedUnitsNames() {
    return this.incident.associatedUnits.map(incidentUnit => {
      const isMe = incidentUnit.driverDetails?.userId === this.currentUserId;
      return {
        dispalyId: isMe ? this.translateService.instant('me') : incidentUnit.displayId,
        isMe: isMe,
        routes: !this.uiStore.isTabletMode
          ? this.getUnitRouteFromDiff(incidentUnit.id)
          : this.entitiesStore.getUnitRouteFromDiff(toJS(incidentUnit.type), incidentUnit.id),
      };
    });
  }

  restoreIncident(event: Event, incident: Incident) {
    event.stopPropagation();
    if (!this.uiStore.isTabletMode) {
      this.entitiesService.emitNewEntitiesDiff({
        modified: {
          INCIDENT: [
            {
              id: incident.id,
              confirmedAt: new Date() as unknown as string,
              confirmedBy: this.usersStore.authUserID,
            },
          ],
        },
      });
    } else {
      incident['confirmedBy'] = this.usersStore.authUserID;
      incident['confirmedAt'] = new Date().toString();
    }
    if (incident.status === IncidentStatus.Rejected) {
      this.incidentStore.undoRejectIncident(incident);
    } else if (incident.status === IncidentStatus.Completed) {
      this.incidentStore.undoCompleteIncident(incident);
    }
  }

  get isEditBlocked() {
    const editUsers = this.incidentStore.editCollaboration[this.incident.id];
    return editUsers && editUsers?.length !== 0 ? true : false;
  }

  checkTypeString(obj) {
    if (typeof obj === 'string') {
      return true;
    }
    return false;
  }

  // in offline mode in order to show translation we need to get it from object
  typeOf(obj, key) {
    if (typeof obj === 'object' && obj !== null) {
      return obj[key];
    } else {
      if (this.incident.id !== this.incidentIdTypeofError && obj === null) {
        datadogLogs.logger.info('IncidentListItemComponent typeOf null object', {
          incident: JSON.stringify(this.incident),
        });
        this.incidentIdTypeofError = this.incident.id;
      }

      return obj;
    }
  }

  ifUnconfirmed(incidentStatus) {
    if (incidentStatus === IncidentStatus.Unconfirmed) return '-unconfirmed';
    return '';
  }

  clickedMergedInto(event: MouseEvent, id?: Maybe<number>) {
    event.stopPropagation();
    const path = this.isTabletMode ? this.tabletIncidentViewPath : this.desktopIncidentViewPath;
    this.router.navigate([path, id]);
  }

  getUnitRouteFromDiff(unitId: number): string[] {
    this.cdr.markForCheck();
    return this.unitQuery.getEntity(unitId)?.routeNames || [];
  }
}
