import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import {
  AuthService,
  AuthUserService,
  CheckVersionService,
  CollaborationService,
  GoogleAnalyticsService,
  HeapAnalyticsService,
  LocalStorageService,
  TourService,
} from '@wc-core';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { ToastrAlertsService } from '@wc/wc-ui/src/services/toaster-alert.service';
import { DesktopUiStateService } from './shared/desktop-ui-state.service';

@Component({
  selector: 'wc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  title = 'desktop';

  readonly isLatestVersion$ = this.checkVersionService.isLatestVersion$;
  readonly newAngularVersion$ = this.checkVersionService.newAngularVersion$;

  constructor(
    private alertsService: ToastrAlertsService,
    private collaborationService: CollaborationService,
    private checkVersionService: CheckVersionService,
    private desktopUiState: DesktopUiStateService,
    googleAnalyticsService: GoogleAnalyticsService,
    private localStorageService: LocalStorageService,
    public authService: AuthService,
    private tourService: TourService, // root injection, please keep,
    private authUserService: AuthUserService,
    private heapService: HeapAnalyticsService
  ) {
    this.heapService.trackUser(this.authUserService.user);
    googleAnalyticsService.init('web');
    this.alertsService.setPortraitDesktopMode(this.desktopUiState.isPortraitDesktopMode.value);
    const expandedSectionState = this.localStorageService.get(LocalStorageKeys.ExpandedSectionsStorage);

    this.desktopUiState.updateIsPortraitDesktopMode(
      !!this.localStorageService.get(LocalStorageKeys.IsPortraitDesktopMode)
    );
    if (expandedSectionState) {
      this.desktopUiState.setExpendedSectionsState(expandedSectionState);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  async beforeunload(event: BeforeUnloadEvent) {
    this.collaborationService.stopCollaboration();
    return;
  }

  // Global Event Listeners
  // These listeners toggle the keyboard interaction state and enhance accessibility features.
  // Note: Consider refactoring these listeners into a separate utility class or a service for better modularity.

  @HostListener('keydown') onKeydown() {
    this.desktopUiState.updateKeyboardInteraction(true);
  }

  @HostListener('mousedown') onMouseDown() {
    this.desktopUiState.updateKeyboardInteraction(false);
  }

  @HostListener('window:keydown.space', ['$event'])
  space(event: KeyboardEvent) {
    const activeElement = document.activeElement as HTMLElement;
    const validTagNames = ['BUTTON', 'A'];
    if (document.activeElement && validTagNames.includes(activeElement.tagName)) {
      event.preventDefault();
      event.stopPropagation();
      activeElement.click();
    }
  }

  refreshPage() {
    window.location.reload();
  }
}
