import { Injectable } from '@angular/core';
import * as Enums from '@wc/core/models/enums';
import {
  AccessModifier,
  CreateRoleInput,
  DeleteRoleInput,
  Role,
  SetRolePermissionsInput,
  UpdateRoleInput,
  User,
  UserRolesDto,
} from '@wc/core/models/gql.models';
import { PermissionsGroup } from '@wc/core/models/PermissionsGroup';
import { AuthUserService } from '@wc/wc-core/src/lib/services/auth-user.service';
import { FormFieldOption } from '@wc/wc-ui/src/lib/base';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RolesApiService } from './roles-api.service';

type PermissionController = {
  modifiers: Set<AccessModifier>;
  value: Enums.permissionsGroupsEnum;
};

type PermissionControllers = Record<string, PermissionController>;

type UserWithRoles = User & { rolesNames: string[]; rolesIds: number[] };

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private _usersWithRoles = new BehaviorSubject<UserWithRoles[]>([]);
  usersWithRoles$ = this._usersWithRoles.asObservable();

  private _usersRoles = new BehaviorSubject<Record<number, Role[]>>({});
  usersRoles$ = this._usersRoles.asObservable();

  private _roles = new BehaviorSubject<Role[]>([]);
  roles$ = this._roles.asObservable();
  rolesMap: Record<number, Role> = {};

  constructor(private authUserService: AuthUserService, private rolesApiService: RolesApiService) {}

  get accountId(): number {
    return this.authUserService.user.account.id;
  }

  set roles(roles: Role[]) {
    this.rolesMap = roles.reduce((acc, role) => ({ ...acc, [role.id]: role }), {});
    this._roles.next(roles);
  }

  get roles(): Role[] {
    return this._roles.value;
  }

  get usersWithRoles(): UserWithRoles[] {
    return this._usersWithRoles.value;
  }

  get usersRoles(): Record<number, Role[]> {
    return this._usersRoles.value;
  }

  get rolesTableFormat() {
    return this.roles.map(role => {
      return {
        ...role,
        permissionsNames: this.getPermissionNames(role),
      };
    });
  }

  getRolesOptions(): FormFieldOption[] {
    if (!this.roles.length) this.loadRoles();

    return this.roles.map(role => ({
      value: role.id,
      displayName: role.name,
    }));
  }

  loadRoles() {
    return this.rolesApiService.rolesByAccount(this.accountId).pipe(tap(roles => (this.roles = roles)));
  }

  createRole(input: CreateRoleInput) {
    return this.rolesApiService.createRole(input).pipe(tap(role => (this.roles = [...this.roles, role])));
  }

  updateRole(input: UpdateRoleInput) {
    return this.rolesApiService.updateRole(input).pipe(
      tap(updatedRole => {
        const updatedRoles = this.roles.map(role => (role.id === updatedRole.id ? updatedRole : role));
        this.roles = updatedRoles;
      })
    );
  }

  deleteRole(input: DeleteRoleInput) {
    return this.rolesApiService.deleteRole(input).pipe(
      tap(() => {
        const updatedRoles = this.roles.filter(role => role.id !== input.roleId);
        this.roles = updatedRoles;
      })
    );
  }

  setRolePermissions(input: SetRolePermissionsInput, updateRoleInput: UpdateRoleInput) {
    return this.rolesApiService.setRolePermissions(input).pipe(
      tap(role => {
        this.updateRole(updateRoleInput).subscribe(_ => {
          role.name = updateRoleInput.name as string;
          role.description = updateRoleInput.description as string;
          const updatedRoles = this.roles.map(r => (r.id === role.id ? { ...role, permissions: role.permissions } : r));
          this.roles = updatedRoles;
        });
      })
    );
  }

  getUsersWithRoles() {
    return this.rolesApiService.getUsersWithRoles().pipe(
      tap((usersWithRoles: UserRolesDto[]) => {
        this._usersWithRoles.next(
          (usersWithRoles || []).map(userWithRole => {
            return {
              id: userWithRole.userId,
              name: userWithRole.userName,
              email: userWithRole.email,
              phoneNumber: userWithRole.phoneNumber,
              rolesNames: userWithRole.roles.map(role => role.name),
              rolesIds: userWithRole.roles.map(role => role.id),
            } as UserWithRoles;
          })
        );

        const usersRolesMap = usersWithRoles.reduce(
          (acc, user) => ({
            ...acc,
            [user.userId]: user.roles,
          }),
          {}
        );
        this._usersRoles.next(usersRolesMap);
      })
    );
  }

  rolePermissionsControllersGroups(role: Role) {
    const permissionsControllers = role.permissions.reduce((acc, permission) => {
      const controller = acc[permission.scope.entity] || { modifiers: new Set<AccessModifier>(), value: '' };
      controller.modifiers.add(permission.scope.modifier);

      const foundGroup = PermissionsGroup.find(group => group.modifiers.some(mod => controller.modifiers.has(mod)));
      controller.value = foundGroup ? foundGroup.type : Enums.permissionsGroupsEnum.NoAccess;

      acc[permission.scope.entity] = controller;
      return acc;
    }, {} as PermissionControllers);

    return permissionsControllers;
  }

  getUserRoleIds(userId: number) {
    return this.getUserRoles(userId).map(role => role.id);
  }

  getUserRoleNames(userId: number) {
    return this.getUserRoles(userId).map(role => role.name);
  }

  getUserRoles(userId: number) {
    return this.usersRoles[userId];
  }

  setUserRoles(user: User, rolesIds: number[]) {
    const { id, name, email, phoneNumber } = user;
    const userId = id;

    const userWithRoles = this.usersWithRoles.find(user => user.id === userId);
    if (userWithRoles) {
      userWithRoles.name = name;
      userWithRoles.email = email;
      userWithRoles.phoneNumber = phoneNumber;
      userWithRoles.rolesIds = rolesIds;
      userWithRoles.rolesNames = rolesIds.sort((a, b) => a - b).map(roleId => this.rolesMap[roleId].name);
      const updatedUsersWithRoles = this.usersWithRoles.map(_user => (_user.id === userId ? userWithRoles : _user));
      this._usersWithRoles.next(updatedUsersWithRoles);
    } else {
      this._usersWithRoles.next([
        ...this.usersWithRoles,
        { ...user, rolesIds, rolesNames: rolesIds.sort((a, b) => a - b).map(roleId => this.rolesMap[roleId].name) },
      ]);
    }

    this.usersRoles[userId] = rolesIds.sort((a, b) => a - b).map(roleId => this.rolesMap[roleId]);
  }

  removeUserRoles(userId: number) {
    const userWithRoles = this.usersWithRoles.find(user => user.id === userId);
    if (userWithRoles) {
      const updatedUsersWithRoles = this.usersWithRoles.filter(user => user.id !== userId);
      this._usersWithRoles.next(updatedUsersWithRoles);
    }

    delete this.usersRoles[userId];
  }

  private getPermissionNames(role: Role) {
    const permissionsControllers = this.rolePermissionsControllersGroups(role);
    return Object.entries(permissionsControllers).map(([entity, { value }]) => `${entity}(${value})`.toLowerCase());
  }
}
