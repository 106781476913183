<div
  class="text-field-control-component"
  [class.disabled]="ngControl.control?.disabled || false"
  [class.read-only]="readOnly">
  <mat-label *ngIf="showLabel && formFieldData?.label" class="field-label" [class.rtl]="rtlDirection">
    <span class="required-section" *ngIf="formFieldData?.required">*</span>
    {{ formFieldData.label || '' | translate }}
  </mat-label>
  <mat-form-field
    appearance="outline"
    [floatLabel]="'never'"
    [class.invalid-field]="ngControl.control?.invalid && ngControl.control?.touched"
    [class.tablet-mode]="false">
    <wc-icon matPrefix *ngIf="prefixIcon" [iconName]="prefixIcon"></wc-icon>
    <input
      data-cy-id="text-field-input"
      [class.uppercase]="transformToUpperCase"
      autocomplete="off"
      #wcInputField
      matInput
      [type]="inputType"
      [errorStateMatcher]="matcher"
      [formControl]="$any(ngControl.control)"
      [placeholder]="formFieldData?.placeholder || '' | translate"
      (input)="inputChanged($any($event.target)?.value)"
      (keydown)="checkValue($event)"
      (blur)="onTouched()"
      data-focus />
    <div matSuffix class="text-field-control-component__suffix">
      <button
        type="button"
        [wcTooltipText]="'clear' | translate"
        *ngIf="ngControl.control?.value && clearButton && !suffixIcon"
        (click)="ngControl.control?.setValue('')"></button>
      <div
        data-cy-id="text-field-character-count"
        matSuffix
        *ngIf="viewCharacterCounter && ngControl.control && maxLength > 0"
        class="text-field-control-component__character-count"
        [class.error]="ngControl.control && ngControl.control.value?.length > maxLength">
        {{ ngControl.control.value?.length || 0 }}/{{ maxLength }}
      </div>
      <button
        *ngIf="suffixIcon"
        type="button"
        class="text-field-control-component__suffix-icon-button"
        data-cy-id="text-field-suffix-button"
        (click)="suffixIconButtonClicked.emit($event)"
        [disabled]="!ngControl.control?.value || disableSuffixButton">
        <wc-icon
          [wcTooltipText]="suffixTooltipText || '' | translate"
          [wcTooltipDisabled]="!suffixTooltipText || !ngControl.control?.value || disableSuffixButton"
          [wcTooltipPlacement]="'top'"
          [iconName]="suffixIcon"></wc-icon>
      </button>
    </div>
    <mat-error data-cy-id="error-message">
      {{
        'validationErrorMessages.' +
          (customValidationMessage && errorMessage === 'unique' ? customValidationMessage : $any(errorMessage))
          | translate
            : {
                min: ngControl.errors?.minlength?.min,
                max: ngControl.errors?.maxlength?.max,
                minLength: ngControl.errors?.minlength?.requiredLength,
                maxLength: ngControl.errors?.maxlength?.requiredLength
              }
      }}
    </mat-error>
  </mat-form-field>
</div>
