<ng-container *ngIf="!!(isLatestVersion$ | async) === false">
  <div [class.disable-click-outside]="newAngularVersion$ | async">
    <div class="version-alert-wrapper">
      <img class="glass" src="/assets/images/glass.svg" alt="new-version-icon" />
      <div class="text">{{ 'newVersion' | translate }}</div>
      <div class="message">{{ 'refreshMessage' | translate }}</div>
      <button class="reload-btn" (click)="refreshPage()">
        <i class="fas fa-undo-alt reload-icon"></i>
        <div class="reload">{{ 'refresh' | translate }}</div>
      </button>
    </div>
  </div>
</ng-container>

<router-outlet>
  <wc-spinner></wc-spinner>
</router-outlet>
