import { Inject, Injectable } from '@angular/core';
import { APP_TYPE_TOKEN } from '@wc/wc-core/src/lib/injection-tokens';
import { LocalStorageKeys } from '@wc/wc-core/src/lib/services/local-storage.service';
import { AppTypeUnion } from '@wc/wc-models';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { BehaviorSubject } from 'rxjs';
import {
  RoadEventsPanelSubTabIndexDesktop,
  RoadEventsPanelSubTabIndexTablet,
  RoadEventsPanelTabIndex,
} from '../../core/models/enums';
import { ExpandedSections, ExpandedSectionType, MainEntityType } from '../models';
import { AppConfigService } from '../services/app-config.service';
import { LocalStorageService } from '../services/local-storage.service';
import { AppFeatureEnum, SplitIOService } from '../services/split-io.service';

export enum OfflineEvent {
  incidentCreated,
}

export type LoaderOptions = {
  routeChange: boolean;
  roadClosure: boolean;
  construction: boolean;
  specialEvent: boolean;
  incident: boolean;
  shift: boolean;
  editShift: boolean;
  analytics: boolean;
  address: boolean;
  shiftDetails: boolean;
  transitPanel: boolean;
  transitMap: boolean;
  trafficDisruptions: boolean;
  tabletFilters: boolean;
  incidentConfigurations: boolean;
  venues: boolean;
  roadwayStatusPanel: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class UiStore {
  /**
   * @deprecated should be used only in tablet
   */
  expandedSections: ExpandedSections = {
    incident: {
      location: true,
      time: true,
      involvedVehicles: false,
      type: false,
      mitigation: false,
      affectedLanes: false,
      cameras: false,
      autoPublish: false,
      notes: false,
      additionalInfo: false,
      source: false,
      casualties: false,
      externalId: false,
      media: false,
      associatedUnits: false,
      severity: false,
      attributes: false,
    },

    road_closure: {
      description: false,
      affectedLanes: false,
      contactPerson: false,
      scheduled: false,
      media: false,
      permit: false,
      cameras: false,
    },
    construction: {
      description: false,
      affectedLanes: false,
      contactPerson: false,
      scheduled: false,
      media: false,
      permit: false,
      cameras: false,
    },
  };
  @observable featureToggles?: SplitIO.Treatments;
  @observable showSupportModal = false;

  // verified only tablet usage:
  @observable showNewIncidentLocationPanel = false;
  @observable showAddressModal = false;
  @observable showCreateIncidentPanel = false;
  @observable showEditIncidentPanel = false;
  @observable showLayersOnSidebar = false;
  @observable showFiltersOnSidebar = false;
  @observable isSidebarOpen = false;
  @observable showCreateNewEntityModal = false;
  @observable showVersionNotification = false;
  offlineEvent$: BehaviorSubject<OfflineEvent | null> = new BehaviorSubject<OfflineEvent | null>(null);

  /**\
   * @deprecated Should be use from desktopUiState, if used at all
   */
  @observable isDirectionRtl = false;
  /**\
   * @deprecated Should be use from desktopUiState
   */
  @observable isHebrew = false;
  /**\
   * @deprecated Should be use from desktopUiState
   */
  @observable roadEventsPanelTabIndex: RoadEventsPanelTabIndex = RoadEventsPanelTabIndex.NeedAction;
  /**\
   * @deprecated Should be use from desktopUiState
   */
  @observable roadEventsInProgressSubTabIndex = 0;
  /**\
   * @deprecated Should be use from desktopUiState
   */
  @observable roadEventsCompletedSubTabIndex = 0;
  /**\
   * @deprecated Should be use from desktopUiState
   */
  @observable isPortraitDesktopMode = false;

  @observable recentInteractedEntityId: {
    id: 0;
    entityLayerType?: MainEntityType;
  } = {
    id: 0,
    entityLayerType: undefined,
  }; //scroll to this item when going back to list view

  /**\
   * @deprecated Should be use from LoaderService
   */
  @observable loaderVisibility: LoaderOptions = {
    routeChange: false,
    roadClosure: false,
    construction: false,
    specialEvent: false,
    incident: false,
    shift: false,
    editShift: false,
    analytics: false,
    address: false,
    venues: false,
    shiftDetails: false,
    transitPanel: false,
    transitMap: false,
    trafficDisruptions: false,
    tabletFilters: false,
    incidentConfigurations: false,
    roadwayStatusPanel: false,
  };

  /**\
   * @deprecated Should be use from desktopUiState
   */
  keyboardInteraction = true;

  /**\
   * @deprecated Should be use from desktopUiState
   */
  isTabletMode: boolean;

  constructor(
    @Inject(APP_TYPE_TOKEN) private _appMode: AppTypeUnion,
    private appConfigService: AppConfigService,
    private splitIOService: SplitIOService,
    private localStorageService: LocalStorageService
  ) {
    makeObservable(this);
    this.isTabletMode = this._appMode === 'tablet';

    // this.setFeatureToggle(this.splitIOService.featureToggles.getValue());
    this.splitIOService.featureToggles.subscribe(featureToggles => {
      this.setFeatureToggle(featureToggles);
    });

    this.appConfigService.load().then(() => {
      // desktop requires incident type to be filled in
      if (!this.isTabletMode) {
        this.expandedSections.incident.type = true;
      }

      // set expanded sections local storage
      const expandedSectionsLocalStorage = localStorageService.get(LocalStorageKeys.ExpandedSectionsStorage);
      if (expandedSectionsLocalStorage) {
        Object.keys(this.expandedSections).forEach(key => {
          Object.keys(this.expandedSections[key]).forEach(section => {
            this.expandedSections[key][section] = expandedSectionsLocalStorage[key][section]
              ? expandedSectionsLocalStorage[key][section]
              : false;
          });
        });
      }

      this.setCurrentExpandedSectionsLocalStorage();
    });
  }

  /**
   * @deprecated use desktopUiState service update function instead
   *  */
  expandedSectionsChanged(expanded: boolean, field: string, expandedSectionType: ExpandedSectionType) {
    this.expandedSections[expandedSectionType][field] = expanded;
    this.setCurrentExpandedSectionsLocalStorage();
  }

  setCurrentExpandedSectionsLocalStorage() {
    this.localStorageService.set(LocalStorageKeys.ExpandedSectionsStorage, this.expandedSections);
  }

  /**\
   * @deprecated Should be use from desktopUiState instead
   */
  setRoadEventsPanelTabIndex(index: RoadEventsPanelTabIndex): void {
    runInAction(() => {
      this.roadEventsPanelTabIndex = index;
    });
  }
  /**\
   * @deprecated Should be use from desktopUiState instead
   */
  setRoadEventsInProgressSubTabIndex(
    index: RoadEventsPanelSubTabIndexDesktop | RoadEventsPanelSubTabIndexTablet
  ): void {
    runInAction(() => {
      this.roadEventsInProgressSubTabIndex = index;
    });
  }
  /**\
   * @deprecated Should be use from desktopUiState instead
   */
  setRoadEventsCompletedSubTabIndex(index: RoadEventsPanelSubTabIndexDesktop | RoadEventsPanelSubTabIndexTablet): void {
    runInAction(() => {
      this.roadEventsCompletedSubTabIndex = index;
    });
  }

  // the values read only  by Tablet -> at road-event-list.cmp
  @action
  setInteractedEntityId(id, type) {
    this.recentInteractedEntityId.id = id;
    this.recentInteractedEntityId.entityLayerType = type;
  }

  /**\
   * @deprecated Should be use from splitIoService instead
   */
  isActiveFeature(featureName: AppFeatureEnum) {
    return this.featureToggles ? this.featureToggles[featureName] === 'on' : false;
  }

  @action
  // loader service
  showLoader(key: keyof LoaderOptions) {
    this.loaderVisibility[key] = true;
    this.loaderVisibility = { ...{}, ...this.loaderVisibility };
  }

  @action
  // loader service
  hideLoader(key: keyof LoaderOptions) {
    this.loaderVisibility[key] = false;
    this.loaderVisibility = { ...{}, ...this.loaderVisibility };
  }

  @action
  // loader service
  resetEventLoaders() {
    this.loaderVisibility['incident'] = false;
    this.loaderVisibility['roadClouser'] = false;
    this.loaderVisibility['construction'] = false;
    this.loaderVisibility['specialEvent'] = false;
    this.loaderVisibility['shiftDetails'] = false;
    this.loaderVisibility['trafficDisruptions'] = false;
    this.loaderVisibility = { ...{}, ...this.loaderVisibility };
  }

  @action

  /**\
   * @deprecated Should be use from splitIoService instead
   */
  setFeatureToggle(featureToggles) {
    this.featureToggles = featureToggles;
  }
  @action
  //tablet only
  closeSidebar() {
    this.isSidebarOpen = false;
    this.showFiltersOnSidebar = false;
    this.showLayersOnSidebar = false;
  }

  @action
  //tablet only
  toggleLayersSidebar() {
    this.showFiltersOnSidebar = false;
    this.showLayersOnSidebar = !this.showLayersOnSidebar;
    this.isSidebarOpen = this.showLayersOnSidebar;
  }

  @action
  //tablet only
  toggleFiltersSidebar() {
    this.showLayersOnSidebar = false;
    this.showFiltersOnSidebar = !this.showFiltersOnSidebar;
    this.isSidebarOpen = this.showFiltersOnSidebar;
  }

  /**\
   * @deprecated Should be use from desktopUiService instead
   */
  @action
  setPortraitDesktopMode(value: boolean) {
    this.localStorageService.set(LocalStorageKeys.IsPortraitDesktopMode, JSON.stringify(value));
    window.location.reload();
  }
}
